<template>
  <div class="TextInput" :class="divDynamicClasses">
    <label :for="id" v-text="label"></label>
    <input
      :id="id"
      type="text"
      :disabled="disabled"
      :placeholder="placeholder ? placeholder : ''"
      @keyup="$emit( 'key-up', $event)"
      v-if="!type"
      v-model="value"
      :class="inputDynamicClasses"
    />
    <input
      :id="id"
      :type="type"
      :disabled="disabled"
      v-if="type === 'date'"
      v-model="value"
      :class="inputDynamicClasses"
    />
    <input
      :id="id"
      :type="type"
      :disabled="disabled"
      :placeholder="placeholder ? placeholder : ''"
      v-if="type === 'password'"
      v-model="value"
      :class="inputDynamicClasses"
      autocomplete="off"
    />
    <div class="errorMessage" v-show="error">
      {{errorMessage}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommunityTextInput',
  data() {
    return {
      errorMessage: "",
      error       : false,
    }
  },
  watch: {
   value (newValue) {
     this.value = newValue
     this.$emit('update:modelValue', newValue)
   }
  },
  props: {
    label       : String,
    modelValue  : String,
    id          : String,
    type        : String,
    disabled    : Boolean,
    required    : Boolean,
    email       : Boolean,
    minLength   : Number,
    maxLength   : Number,
    placeholder : String,
    whiteBg     : {
      type    : Boolean,
      default : false
    },
    isAuthInput : {
      type    : Boolean,
      default : false
    }
  },
  computed: {
    divDynamicClasses ( ) {
      return [
        this.isAuthInput && 'p-0'
      ].join( ' ' ).split( ' ' );
    },
    inputDynamicClasses ( ) {
      return [
        this.whiteBg ? 'white-bg' : '',
        this.error ? 'error' : '',
        this.isAuthInput && 'fs-1'
      ].join( ' ' ).split( ' ' );
    },
    value: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
      }
    }
  },
  methods: {
    validate() {
      if (this.required === true && this.value.length===0){
         this.error = true;
         this.errorMessage = this.$t('validation_required');
         return this.id;
      } else if (this.minLength && this.minLength!=="" && this.value.length<this.minLength){
          this.error = true;
          this.errorMessage = this.$t('validation_min_length')+this.minLength;
          return this.id;
      } else if (this.maxLength && this.maxLength!=="" && this.value.length>this.maxLength){
          this.error = true;
          this.errorMessage = this.$t('validation_max_length')+this.maxLength;
          return this.id;
      } else if (this.email === true && (!this.value.includes("@") || !this.value.includes("."))){
          this.error = true;
          this.errorMessage = this.$t('validation_email');
          return this.id;
      } else {
        this.error = false;
        this.errorMessage = "";
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .TextInput {
    padding           : .5rem;
    display           : flex;
    flex-direction    : column;
    width             : 50%;
    font-family: Roboto condensed;

    @media screen and ( max-width: 768px ) {
      width           : 100%;
    }

    label {
      text-transform  : uppercase;
      font-weight     : 600;
      font-size       : .8rem;
      padding-bottom  : 5px;
    }

    input, input[type="date"] {
      background      : #f4f4f4;
      border          : 1px solid #e4e4e4;
      font-size       : 1.3rem !important;
      padding         : .5rem 1rem;
      width           : 100%;
      font-family: Roboto condensed;

      &:disabled {
        cursor        : not-allowed;
      }

      &[type="date"] {
        font-family   : inherit;
        font-size     : inherit;
      }

      &:focus {
        outline       : none;
      }
    }
  }

  .white-bg {
    background        : white !important;
  }

  .error {
    border:1px solid #d9534f!important;
    background-color: #ffdfde !important;
  }

  .p-0 {
    padding           : 0 !important;
  }

  .fs-1 {
    font-size         : 1rem !important;
  }
  .errorMessage{
    background-color: #d9534f;
    color: white;
    padding: 3px;
    font-size: 0.9em;
  }
</style>
