<template>
  <div class="CommunityErrors">
    <p v-for="e in errors">{{e}}</p>
  </div>
</template>

<script>
export default {
  name: 'CommunityErrors',
  props:{
    errors:{
      type:Array,
    }
  }
}
</script>
<style lang="scss" scoped>
.CommunityErrors{
  background: #FFDFDF;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0.01em;
  color: #D90000;
  padding: 13px 15px;
  margin: 20px 0;
  width: 100%;
}
</style>
