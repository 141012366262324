<template>
  <div class="SelectInput">
    <label :for="id" v-text="label"></label>
    <select
      :id="id"
      @change="$emit( 'change', $event.target.value, id )"
      :class="whiteBg && 'white-bg'"
    >
    <option
    :key="-1"
    :value="-1"
      v-if = "emptyVal"
    >{{emptyValName}}</option>
      <option
        v-for="val in value"
        :key="val.id"
        :value="val.id"
        :selected="val.id == selected"
      >{{ $t(val.value) }}</option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'SelectInput',
  props: {
    label     : String,
    value     : Array,
    id        : String,
    selected  : String,
    whiteBg   : Boolean,
    emptyVal  : Boolean,
    emptyValName : String
  }
}
</script>

<style lang="scss" scoped>
  .SelectInput {
    padding         : .5rem;
    display         : flex;
    flex-direction  : column;
    width           : 50%;


    @media screen and ( max-width: 768px ) {
      width         : 100%;
    }

    .white-bg {
      background    : white !important;
    }

    label {
      text-transform: uppercase;
      font-weight   : 600;
      font-size     : .8rem;
      padding-bottom: 5px;
    }

    select {
      background    : #f4f4f4;
      border        : 1px solid #e4e4e4;
      font-size     : 1.2rem;
      padding       : .5rem 1rem;
      width         : 100%;
      height        : 40px;

      &:required:invalid {
        color       : gray;
      }

      &::placeholder {
        color       : #929292;
      }

      &:focus {
        outline     : none;
      }
    }

    option {
      color         : black;

      &[value=""][disabled] {
        display     : none;
      }

      &:focus {
        outline     : none;
      }
    }

  }
</style>
