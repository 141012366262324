<template>
  <div class="GradientBack">
    <CommunityHeader :title="title" :shadowText="shadowText"/>
    <div class="GradientBack--Slot" v-if="!this.$slots.footer">
      <slot />
    </div>
  </div>
</template>

<script>
import CommunityHeader from '@/components/CommunityHeader.vue'
export default {
  name: 'GradientBackLayout',

  props:{
    title: "",
    shadowText: ""
  },
  components:{
    CommunityHeader
  }
}
</script>

<style scoped lang="scss">
body{
    font-family: Roboto condensed;
}
.GradientBack{
  height: calc(100vh - 70px);
  overflow-y: auto;
  &::-webkit-scrollbar-track{
    background-color: #e6e6e6;
  }
  &::-webkit-scrollbar {
    background-color: #8134ff;
    width: 3px;
  }
  &::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background: var(--primary-color);
  }
  &--Slot{
    font-family: Roboto condensed;
    padding: 0 5% 50px 5%;
    @media only screen and (max-width: 600px) {
    padding: 0 3.5% 50px 3.5%;

  }
  }
}
.swal-overlay{
  font-family: Roboto condensed !important;
}


</style>
