<template>
  <div class="CommunityHeader">
    <h1 class="CommunityHeader--pageTitle">{{$t(title)}}</h1>
    <h1 class="CommunityHeader--shadowText">{{$t(shadowText)}}</h1>
  </div>
</template>

<script>
export default {
  name: 'CommunityHeader',

  props:{
    title: "",
    shadowText: ""
  }

}
</script>

<style scoped lang="scss">
body{
    font-family: Roboto condensed;
}
.CommunityHeader{
  width: 100%;
  display:flex;
  justify-content: space-between;
  position: relative;
  height: 150px;
  background: rgba(0, 0, 0, 0.22);
  display: flex;
  align-items: center;
  margin-bottom: -59px;
  padding: 0 5%;
  @media only screen and (max-width: 600px){
    padding: 0;
  }
  &--shadowText{
    font-family: Roboto condensed;
    font-style: normal;
    font-weight: bold;
    position: absolute;
    color: white;
    font-size: 75px;
    opacity: 0.13;
    margin-top: -30px;
    margin-left: 0px;
    @media only screen and (max-width: 600px) {
      font-size: 70px;
    }
  }
  &--pageTitle{
    margin-left: 30px;
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 43px;
    color: white;
    @media only screen and (max-width: 600px) {
      font-size: 29px;
    }
  }
}
</style>
