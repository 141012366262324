<template>
  <div class="Separator" :style="{'border-top':`${borderWidth} ${borderType} #E0E0E0`}">
  </div>
</template>
<script>
export default {
  name: 'Separator',
  props:{
    borderType: {
      type: String,
      default: 'solid'
    },
    borderWidth:{
      type: String,
      default: '1px'
    }
  }
}
</script>
<style scoped lang="scss">
.Separator{
  width: 100%;
  margin-top: 10px;
  margin-bottom: 15px;
}
</style>
