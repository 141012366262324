<template>
  <div :class="dark ? 'spinner colored' : 'spinner'"></div>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    dark: Boolean
  },
}
</script>

<style lang="scss" scoped>
  .spinner {
    display         : inline-block;
    position        : relative;
    width           : 16px !important;
    height          : 16px;
    margin          : 0 !important;
    &:after {
      content       : " ";
      display       : block;
      border-radius : 50%;
      width         : 0;
      height        : 0;
      margin        : 1.5px;
      box-sizing    : border-box;
      border        : 7px solid #fff;
      border-color  : #fff transparent #fff transparent;
      animation     : spinner 1.2s infinite;
    }

    &.colored:after {
      border        : 7px solid #666;
      border-color  : #666 transparent #666 transparent;
    }

    @keyframes spinner {
      0% {
        transform                 : rotate(0);
        animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
      50% {
        transform                 : rotate(900deg);
        animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
      }
      100% {
        transform                 : rotate(1800deg);
      }
    }
  }
</style>